
import Dashboard from '@/views/brand/Dashboard';
// import store from '@/store'

const routes = [
	{
		path: '/brand',
		name: 'brandDashboard',
		component: Dashboard,
		meta: {
			pageTitle: 'Dashboard',
			authFor: 'brand'
		}
	},
	{
		path: '/brand/add-brand/:id?',
		name: 'addBrand',
		meta: {
			pageTitle: 'Create/Edit Brand',
			authFor: 'brand'
		},
		component: () => import(/* webpackChunkName: "addBrand" */ '@/views/brand/AddBrand.vue')
	},
	{
		path: '/brand/briefs',
		name: 'brandBriefs',
		meta: {
			pageTitle: 'Briefs',
			authFor: 'brand',
			pageTheme: 'green',
		},
		component: () => import(/* webpackChunkName: "brandBriefs" */ '@/views/brand/Briefs.vue')
	},
	{
		path: '/brand/brief/:id',
		name: 'brandBrief',
		meta: {
			pageTitle: 'Brief Detail',
			authFor: 'brand',
			pageTheme: 'green',
		},
		component: () => import(/* webpackChunkName: "brandBrief" */ '@/views/brand/Brief.vue')
	},
	{
		path: '/brand/add-brief/:id?',
		name: 'brandAddBrief',
		meta: {
			pageTitle: 'Add a Brief',
			authFor: 'brand',
			pageTheme: 'green',
		},
		component: () => import(/* webpackChunkName: "brandAddBrief" */ '@/views/brand/AddBrief.vue')
	},
	{
		path: '/brand/treatments',
		name: 'brandTreatments',
		meta: {
			pageTitle: 'Treatments',
			authFor: 'brand',
			pageTheme: 'yellow',
		},
		component: () => import(/* webpackChunkName: "brandTreatments" */ '@/views/brand/Treatments.vue')
	},
	{
		path: '/brand/treatment/:id',
		name: 'brandTreatment',
		meta: {
			pageTitle: 'Treatment',
			authFor: 'brand',
			pageTheme: 'yellow',
		},
		component: () => import(/* webpackChunkName: "brandTreatment" */ '@/views/brand/Treatment.vue')
	},
	{
		path: '/brand/help/',
		name: 'brandHelp',
		meta: {
			pageTitle: 'Help',
			authFor: 'brand',
			pageTheme: 'green',
		},
		component: () => import(/* webpackChunkName: "brandTreatment" */ '@/views/brand/Help.vue')
	},
]

export default routes
