<template>
	<v-list-group class="ps-user" appendIcon="" >
		<template v-slot:activator>

			<ps-avatar :image="avatar" :border-color="avatarColor" size="medium"></ps-avatar>
			<v-list-item-content class="align-content-center">
				<v-list-item-title class="ps-user__title title primary--text font-weight-xtrabold text-uppercase">{{ user.user_name }}</v-list-item-title>
				<v-list-item-subtitle class="font-weight-bold ps-user__subtitle sub-title-1 text-uppercase">{{ userSubtitle }}</v-list-item-subtitle>
			</v-list-item-content>
		</template>
		<v-list>
			<v-list-item @click="logout()">
				<v-list-item-icon>
					<v-icon small>fas fa-sign-out-alt</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title class="text-uppercase">Logout</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list>
	</v-list-group>
</template>

<script>
import psAvatar from "@/components/Avatar.vue";
import {mapActions, mapGetters} from "vuex";

export default {
	components: {
		psAvatar
	},
	methods: {
		...mapActions({
			logoutAction: 'auth/logout'
		}),

		logout() {
			this.logoutAction().then(() => {
				this.$router.replace({
					name: 'login'
				})
			})
		},
	},
	data() {
		return {

		}
	},

	computed: {
		...mapGetters({
			user: 'auth/user',
			currentBrand: 'auth/currentBrand',
		}),

		avatar() {
			if (this.user.avatar) {
				return this.user.avatar;
			}
			else {
				return `https://api.adorable.io/avatars/150/${this.user.email}`;
			}
		},
		avatarColor() {
			if (this.user.brand === 1) {
				return 'psblue';
			}
			else {
				return 'pspink';
			}
		},
		userSubtitle() {

			if (this.user.type === 'Brand' && this.currentBrand) {

				return this.currentBrand.title;
			}
			else {
				return this.user.type
			}
		},
	}
}
</script>

<style lang="scss" scoped>
	.ps-user {
		padding-top: $spacer-5;
		&__title {
			line-height: 1 !important;
		}
		&__subtitle {
			color: var(--user-subtitle-color) !important;
		}
	}
</style>
