import '@mdi/font/css/materialdesignicons.css';
import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
	icons: {
		iconfont: 'fa'
	},
	theme: {
		dark: true,
		options: {
			customProperties: true,
		},
		themes: {

			dark: {
				primary: '#00aeef',
				secondary: '#ed145b',
				psblue: '#00aeef',
				pspink: '#ed145b',
				psgreen: '#24e949',
				psyellow: '#eeff03',
				psorange: '#ff8401',
				success: '#24e949',
				error: '#ed1212',
				psgreydark: '#909090',
				psgreydarker: '#3b3b3b',
				psgreydarkest: '#333333',
				elevation_0: '#1A1A1A',
				elevation_1: '#1C1C1C',
				elevation_2: '#1F1F1F',
				elevation_12: '#333333',
				elevation_16: '#363636',
				black: '#171717',
			}
		},
	},
});
