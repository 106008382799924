<template>
    <v-app id="inspire" :class="`theme-${baseTheme} theme-${pageTheme}`">


		<ps-navbar />

        <v-main>
            <v-container fluid fill-height align-start justify-center class="ps-dashboard">
                <ps-flash-message></ps-flash-message>
				<v-alert
					v-if="isIE11"
					type="warning"
				>
					<span class="white--text">It seems you are using Internet Explorer which is an older browser which <a class="white--text" target="_blank" href="https://techcommunity.microsoft.com/t5/microsoft-365-blog/microsoft-365-apps-say-farewell-to-internet-explorer-11-and/ba-p/1591666">will be retired this year</a>.<br>Please note, in order to experience this site as intended, we recommend using a modern browser such as  <a class="white--text" target="_blank" href="https://www.microsoft.com/en-us/edge">Microsoft Edge</a>,  <a class="white--text" target="_blank" href="https://www.apple.com/safari/">Apple Safari</a>, <a class="white--text" target="_blank" href="https://www.google.com/chrome/">Google Chrome</a> or  <a class="white--text" target="_blank" href="https://www.mozilla.org/en-US/firefox/new/">FireFox</a>.</span>
				</v-alert>
                <router-view></router-view>
            </v-container>
        </v-main>

        <!-- <ps-Footer /> -->
    </v-app>
</template>

<script>
	import psNavbar from "@/components/layout/Navbar";
	import psFlashMessage from "@/components/layout/FlashMessage";
	import {mapGetters, mapActions} from 'vuex';

	export default {
		data: () => ({
			pageTitle: 'Filmer.tv'
		}),
		props: {
		},
		computed: {
			...mapGetters({
				user: 'auth/user',
			}),
            baseTheme() {
                return this.$store.state.baseTheme;
            },
            pageTheme() {
                return this.$store.state.pageTheme;
			},
			isIE11() {
				return !!window.MSInputMethodContext && !!document.documentMode
			}
		},
		components: {
			psNavbar,
			psFlashMessage
		},
		methods: {
			...mapActions({
				logoutAction: 'auth/logout'
			}),

			logout() {
				this.logoutAction().then(() => {
					this.$router.replace({
						name: 'login'
					})
				})
			},
		},
		created() {
			this.$axios.interceptors.response.use(undefined, function (err) {
				return new Promise(() => {
					if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
						// if you ever get an unauthorized, logout the user
						this.logout();
					}
				throw err;
				});
			});
		},
	};
</script>

<style lang="scss">
    @import "./scss/_font-size-overrides.scss";
    @import "./scss/_font-color-overrides.scss";
    @import "./scss/_custom-utilities.scss";
    @import "./scss/global-overrides/_index.scss";

    .ps-dashboard {
        @media #{$screen-lg-up} {
            padding-left: $dashboard-container-padding-x !important;
            padding-right: $dashboard-container-padding-x !important;
        }
        @media #{$screen-xl-up} {
            padding-left: $dashboard-container-padding-x-xl-up !important;
            padding-right: $dashboard-container-padding-x-xl-up !important;
        }
	}

    :root {
        --squiggle-vertical: url('~@/assets/squiggle_vertical_pink.svg');
        --squiggle-vertical-small: url('~@/assets/squiggle_vertical_pink--small.svg');
        --header-color: var(--v-psblue-base);
        --user-subtitle-color: var(--v-pspink-base);
    }

    .theme-brand {
        --v-primary-base: var(--v-psblue-base);
        --v-anchor-base: var(--v-psblue-base);
        --v-secondary-base: var(--v-pspink-base);
    }

    .theme-brand {
        &.theme-green,
        &.theme-yellow,
        &.theme-orange,
        &.theme-purple,
        {
            --squiggle-vertical: url('~@/assets/squiggle_vertical_blue.svg');
            --squiggle-vertical-small: url('~@/assets/squiggle_vertical_blue--small.svg');
            --header-color: var(--v-secondary-base);
        }
    }

    .theme-creative {
        --v-primary-base: var(--v-pspink-base);
        --v-anchor-base: var(--v-pspink-base);
        --v-secondary-base: var(--v-psblue-base);
        --squiggle-vertical: url('~@/assets/squiggle_vertical_blue.svg');
        --squiggle-vertical-small: url('~@/assets/squiggle_vertical_blue--small.svg');
        --header-color: var(--v-pspink-base);
        --user-subtitle-color:  var(--v-psblue-base);
    }

    .theme-creative {
        &.theme-green,
        &.theme-yellow,
        &.theme-orange,
        &.theme-purple,
        {
            --header-color: var(--v-secondary-base);
            --squiggle-vertical: url('~@/assets/squiggle_vertical_pink.svg');
            --squiggle-vertical-small: url('~@/assets/squiggle_vertical_pink--small.svg');
        }
    }

    .theme-green {
        --v-secondary-base: var(--v-psgreen-base);
    }
    .theme-yellow {
        --v-secondary-base: var(--v-psyellow-base);
    }
    .theme-orange {
        --v-secondary-base: var(--v-psorange-base);
    }

</style>
