<template>
	<v-card height="100%" width="100%" flat  class="ps-brief-card d-flex flex-column fill-width">

		<v-img class="white--text align-end" :aspect-ratio="2/1" :src="coverImg" eager>
			<template v-slot:placeholder>
				<v-row class="fill-height ma-0" align="center" justify="center">
					<v-progress-circular indeterminate color="grey"></v-progress-circular>
				</v-row>
			</template>
		</v-img>

		<v-card-title class="psgreydark--text title text-uppercase font-weight-black break-word">{{brief.project_name}}</v-card-title>

		<v-card-text class="py-2 flex-grow-1" v-if="detailed">

			<v-row v-if="user && user.type == 'Brand'">
				<v-col class="py-0 font-weight-bold">Status:</v-col>
				<v-col class="py-0 font-weight-medium">{{ brief.status }}</v-col>
			</v-row>
			<v-row v-if="user && user.type != 'Brand'">
				<v-col class="py-0 font-weight-bold">Brand:</v-col>
				<v-col class="py-0 font-weight-medium">{{ brief.brand.title }}</v-col>
			</v-row>
			<v-row>
				<v-col class="py-0 font-weight-bold">Category:</v-col>
				<v-col class="py-0 font-weight-medium">{{ brief.category }}</v-col>
			</v-row>
			<v-row>
				<v-col class="py-0 font-weight-bold">Budget:</v-col>
				<v-col class="py-0 font-weight-medium">R {{ budget | numberFormat }}</v-col>
			</v-row>
			<v-row v-if="user && user.type != 'Brand'">
				<v-col class="py-0 font-weight-bold">Country:</v-col>
				<v-col class="py-0 font-weight-medium">{{ brief.country.name }}</v-col>
			</v-row>
			<v-row v-if="user && user.type != 'Brand' && brief.region">
				<v-col class="py-0 font-weight-bold">Region:</v-col>
				<v-col class="py-0 font-weight-medium">{{ brief.region }}</v-col>
			</v-row>
			<v-row>
				<v-col class="py-0 font-weight-bold">Treatments Due:</v-col>
				<v-col class="py-0 font-weight-medium">{{ brief.treatment_due | moment("Do MMMM YYYY") }}</v-col>
			</v-row>

			<p v-if="user && user.type != 'Brand'" class="mt-3 mb-0">{{ brief.strapline }}</p>

		</v-card-text>

		<v-card-actions>
			<v-row justify="space-between" align="center">

				<v-col v-if="user && user.type == 'Brand'" class="px-0">
					<v-btn v-if="canEdit" color="primary" class="mr-4" :to="{ name: 'brandAddBrief', params: { id: brief.id } }">Edit</v-btn>
					<v-btn v-else target="_blank" color="primary" :href="`/brand/brief/${brief.id}`">
						View
					</v-btn>
				</v-col>

				<v-col v-if="user && user.type != 'Brand'" class="px-0">
					<v-btn v-if="inProgress" color="grey" class="mr-4" :to="{ name: briefDetailRoute, params: { id: brief.id } }">In Production</v-btn>
					<v-btn v-else-if="isClosed" color="grey" class="mr-4" :to="{ name: briefDetailRoute, params: { id: brief.id } }">Complete</v-btn>
					<v-btn v-else-if="!canSubmitTreatment" color="grey" class="mr-4" :to="{ name: briefDetailRoute, params: { id: brief.id } }" >In Review</v-btn>
					<v-btn v-else target="_blank" color="primary" :href="`/creative/brief/${brief.id}`">
						View
					</v-btn>
				</v-col>

				<v-col class="py-0 font-weight-bold primary--text text-no-wrap">
					<vac :start-time="$moment().valueOf()" :end-time="$moment(brief.treatment_due.replace('00:00:00', '23:59:00')).add(1, 'day').valueOf()">
						<template v-slot:process="{ timeObj }">
							<div>
								<v-icon class="mr-2" color="#ed145b">mdi-clock</v-icon>
								<span>{{ parseInt(timeObj.d)}}</span> {{ timeObj.d === 1 ? 'Day' : 'Days'}}
								<span>{{timeObj.h}}</span> {{ timeObj.h === 1 ? 'Hour' : 'Hours'}} left
							</div>
						</template>
						<template v-slot:finish></template>
					</vac>
				</v-col>


				<!-- <v-col v-if="showStatus" class="text-right">
					<span class="body-1 psgreydark--text font-weight-bold">{{ brief.status }}</span>
					<v-tooltip top>
						<template v-slot:activator="{ on }">
							<v-btn v-if="brief.status == 'Published'" color="primary" fab small v-on="on">
								<v-icon>fa-check</v-icon>
							</v-btn>

							<v-btn v-else-if="brief.status == 'Closed'" color="primary" fab small v-on="on">
								<v-icon>fa-times</v-icon>
							</v-btn>

							<v-btn v-else-if="brief.status == 'Draft'" color="primary" fab small v-on="on">
								<v-icon>fa-exclamation</v-icon>
							</v-btn>

							<v-btn v-else-if="brief.status == 'Awaiting approval'" color="primary" fab small v-on="on">
								<v-icon>fa-question</v-icon>
							</v-btn>
						</template>
						<span>{{ brief.status }}</span>
					</v-tooltip>
				</v-col> -->
			</v-row>

		</v-card-actions>
	</v-card>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	props: {
		brief: Object,
		detailed: Boolean
	},
	computed: {
		...mapGetters({
			user: 'auth/user'
		}),

		coverImg() {
			if (this.brief.image) {
				return this.brief.image;
			}
			else {
				return '/images/placeholder.jpg';
			}
		},

		briefDetailRoute() {
			if (this.user && this.user.type == 'Creative') {
				return 'creativeBrief';
			}
			else {
				return 'brandBrief';
			}
		},

		canEdit() {
			return this.user && this.brief.user_id == this.user.id && this.brief.status == 'Draft';
		},

		canSubmitTreatment() {
			var now = this.$moment()
			return this.brief.treatment_accepted === 0 && this.$moment(this.brief.treatment_due).diff(now, 'days') >= 0;
		},

		inProgress() {
			return this.brief.status === 'In progress';
		},

		isClosed () {
			return this.brief.status === 'Closed';
		},


		budget() {
			if (this.user.type == 'Brand') {
				return this.brief.budget;
			}
			else {
				return this.brief.project_budget
			}
		}
	}
}
</script>

<style lang="scss" scoped>
	.v-responsive {
		flex: unset;
	}
</style>
