<template>
	<v-dialog v-model="videoWindow" overlay-opacity="0.8" max-width="800px" >
		<template v-slot:activator="{ on }">
			<v-card flat v-on="on" class="ps-video-card">
				<v-img height="200px" :lazy-src="cover">
					<v-row class="fill-height ma-0" align="center" justify="center">
						<span class="ps-video-card__icon">
							<v-icon x-large color="psgreydark" >fas fa-play-circle</v-icon>
						</span>
					</v-row>
				</v-img>
				<v-card-title :class="`${colour}--text title text-uppercase  font-weight-xtrabold`" v-if="title">{{ title }}</v-card-title>
				<v-card-subtitle class="psgreydark--text subtitle-1 font-weight-medium" v-if="subtitle">{{ subtitle }}</v-card-subtitle>
			</v-card>
		</template>

		<div>
			<v-card>
				<div class='embed-wrapper'>
					<iframe v-if="videoWindow" frameborder='0' allowfullscreen :src="embedUrl" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
				</div>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text @click="videoWindow = false">Close</v-btn>
				</v-card-actions>
			</v-card>
		</div>
	</v-dialog>
</template>

<script>
export default {
	props: {
		embedId: String,
		title: String,
		subtitle: String,
		source: String,
		cover: String,
	},
	data() {
		return {
			videoWindow: false,
		}
	},
	computed: {
		embedUrl() {
			if (this.source == 'youtube') {
				return `https://www.youtube.com/embed/${this.embedId}`;
			}
			else if (this.source == 'vimeo') {
				return `https://player.vimeo.com/video/${this.embedId}`;
			}
			return false;
		},
	}
}
</script>

<style lang="scss">
.ps-video-card {
	&__icon {
		position: relative;
		&::before {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 90%;
			height: 90%;
			background-color: map-get($material-dark-elevation-colors, '2');
			border-radius: 50%;
		}
	}
}

.embed-wrapper { 
	position: relative;
	display: block;
	width: 100%;
	padding: 0;
	overflow: hidden;

	&:before {
		content: "";
		display: block;
		padding-top: 56.25%;
	}

	iframe { 
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0;
	}
} 
</style>