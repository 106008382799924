<template>
	<v-card height="100%" width="100%" flat class="ps-treatment-card">
		<v-img class="white--text align-end" :aspect-ratio="2/1" :src="coverImg" eager>
			<template v-slot:placeholder>
				<v-row class="fill-height ma-0" align="center" justify="center">
					<v-progress-circular indeterminate color="grey"></v-progress-circular>
				</v-row>
			</template>
		</v-img>

		<v-card-title class="psgreydark--text title text-uppercase font-weight-black">{{project.brief.project_name}}</v-card-title>

		<v-card-subtitle class="pb-0 font-weight-bold">{{ project.brief.brand.title }}</v-card-subtitle>

		<v-card-text class="py-2 flex-grow-1" v-if="detailed">
			<v-row>
				<v-col class="py-0 font-weight-bold">Category:</v-col>
				<v-col class="py-0 font-weight-medium">{{ project.brief.category }}</v-col>
			</v-row>
			<v-row>
				<v-col class="py-0 font-weight-bold">Budget:</v-col>
				<v-col class="py-0 font-weight-medium">R {{ budget | numberFormat }}</v-col>
			</v-row>
			<v-row>
				<v-col class="py-0 font-weight-bold">Deliverables Due:</v-col>
				<v-col class="py-0 font-weight-medium">{{ project.brief.deliverables_due | moment("Do MMMM YYYY") }}</v-col>
			</v-row>
			<!-- <p class="mt-3">{{ project.brief.short_description }}</p> -->
		</v-card-text>


		<v-card-actions>
			<v-row justify="space-between">

				<v-col class="px-0">
					<v-btn color="primary" :to="{ name: 'projectDetail', params: { id: project.id } }">
						View
					</v-btn>
				</v-col>

				<!-- <v-col class="text-right">
					<v-tooltip top>
						<template v-slot:activator="{ on }">
							<v-btn v-if="treatment.status === 'Approved'" color="primary" fab small v-on="on">
								<v-icon>fa-check</v-icon>
							</v-btn>

							<v-btn v-if="treatment.status === 'Awarded'" color="primary" fab small v-on="on">
								<v-icon>fa-check</v-icon>
							</v-btn>

							<v-btn v-else-if="treatment.status === 'Rejected'" color="primary" fab small v-on="on">
								<v-icon>fa-times</v-icon>
							</v-btn>

							<v-btn v-else-if="treatment.status === 'Submitted'" color="primary" fab small v-on="on">
								<v-icon>fa-exclamation</v-icon>
							</v-btn>

							<v-btn v-else-if="treatment.status === 'Shortlist'" color="primary" fab small v-on="on">
								<v-icon>fa-star</v-icon>
							</v-btn>
						</template>
						<span>{{ treatment.status }}</span>
					</v-tooltip>
				</v-col> -->
			</v-row>

		</v-card-actions>
	</v-card>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	props: {
		project: Object,
		detailed: Boolean,
	},
	computed: {
		...mapGetters({
			user: 'auth/user'
		}),

		coverImg() {
			if (this.project.brief.image) {
				return this.project.brief.image;
			}
			else {
				return `https://placeimg.com/420/200/any?v=${this.project.brief.id}`;
			}
		},

		budget() {
			if (this.user.type == 'Brand') {
				return this.project.brief.budget;
			}
			else {
				return this.project.brief.project_budget
			}
		}
	}
}
</script>

<style>

</style>
