export default {
	namespaced: true,
	state: {
		token: null,
		user: null,
		myBriefs: [],
		settings: {},
		isImpersonating: false,
		myBrands: [],
		currentBrand: null
	},
	mutations: {
		SET_TOKEN(state, token) {
			state.token = token;
		},

		SET_USER(state, user) {
			state.user = user;
		},

		SET_BRIEFS(state, briefs) {
			state.myBriefs = briefs;
		},

		SET_BRANDS(state, brands) {
			state.myBrands = brands;
		},

		SET_BRAND(state, brand) {
			localStorage.setItem('currentBrand', JSON.stringify(brand));
			state.currentBrand = brand;
		},

		SET_SETTINGS(state, settings) {
			state.settings = settings;
		},

		TOGGLE_IMPERSONATE(state) {
			state.isImpersonating = !state.isImpersonating;
		}
	},
	getters: {
		authenticated(state) {
			if (state.token && state.user) {
				return true;
			}
			return false;
		},

		user(state) {
			return state.user;
		},

		myBriefs(state) {
			return state.myBriefs;
		},

		currentBrand(state) {

			let b = localStorage.getItem('currentBrand');
			if(b != 'undefined') {
				state.currentBrand =  JSON.parse(b);
				return state.currentBrand
			}

			return null;
		},

		myBrands(state) {
			return state.myBrands;
		},

		settings(state) {
			return state.settings;
		},

		impersonating(state) {
			return state.isImpersonating;
		}
	},
	actions: {
		async signIn({ dispatch }, credentials) {
			let response = await window.axios.post('/auth/login', credentials);
			return dispatch('attempt', response.data.data.token);
		},

		async switchBrand({dispatch, commit, getters}, id) {

			let currentBrand = getters.currentBrand;
			let response = await window.axios.get(`/brands/switch/${id}`);

			if(response.data.data) {
				commit('SET_BRAND', response.data.data);
			} else {
				commit('SET_BRAND', currentBrand);
			}
			return getters.currentBrand
		},

		async impersonate({dispatch, commit}, id) {
			let response = await window.axios.get(`/impersonate/take/${id}`);
			commit('TOGGLE_IMPERSONATE');
			return dispatch('attempt', response.data.data.token);
		},

		async stopImpersonate({dispatch, commit}) {
			let response = await window.axios.get(`/impersonate/leave`);
			commit('TOGGLE_IMPERSONATE');
			return dispatch('attempt', response.data.data.token);
		},

		async attempt({ commit, state, getters }, token) {
			let parent = this;

			if (token) {
				commit('SET_TOKEN', token);
			}

			if(!state.token) {
				return;
			}

			try {

				let response = await window.axios.get('/me');

				//Set a bunch of user related data in the store for site wide use

				//Set user profile data
				commit('SET_USER', response.data.data);
				let brand = response.data.data.brand
				commit('SET_BRAND', brand);
				commit('SET_BRANDS', response.data.data.brands);

				//get site settings
				let settings = await window.axios.get('/settings');
				commit('SET_SETTINGS', settings.data.data);

				//Store users briefs in store, if it is a brand user
				if(response.data.data.type == 'Brand') {
					let briefRequest = await window.axios.get('/me/briefs');
					commit('SET_BRIEFS', briefRequest.data.data);
				}
			} catch (error) {
				if(error.response) {
					parent.commit('setFlash', {
						type: 'error',
						message: `${error.response.data.message}.`
					});

					//403 = email not validated. Allow user to remain logged in if this is the case
					//So they will be able to re send the validation email
					if (error.response.data.message != 'Your email address is not verified.') {
						commit('SET_TOKEN', null);
						commit('SET_USER', null);
					}
				}
			}
		},

		logout({ commit }) {
			return window.axios.post('/auth/logout').then(() => {
				commit('SET_TOKEN', null);
				commit('SET_USER', null);
			});
		}
	},
	modules: {
	}
}
