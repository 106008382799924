import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import store from './store'
import VueMeta from 'vue-meta'
import colors from 'vuetify/lib/util/colors'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
// don't forget to import CSS styles
import 'tiptap-vuetify/dist/main.css'
import vueAwesomeCountdown from 'vue-awesome-countdown'

require('@/store/subscriber');

Vue.config.productionTip = false;

Vue.use(vueAwesomeCountdown, 'vac')

Vue.use(TiptapVuetifyPlugin, {
	// the next line is important! You need to provide the Vuetify Object to this place.
	vuetify, // same as "vuetify: vuetify"
	// optional, default to 'md' (default vuetify icons before v2.0.0)
	iconsGroup: 'fa'
})
Vue.config.devtools = true
Vue.use(require('vue-moment'));

//Filters
Vue.filter('numberFormat', function (value) {
	return new Intl.NumberFormat().format(Math.round(value));
})

store.dispatch('auth/attempt', localStorage.getItem('token')).then(() => {
	new Vue({
		router,
		vuetify,
		TiptapVuetifyPlugin,
		store,
		VueMeta,
		colors,
		render: h => h(App)
	}).$mount('#app')
});


