import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth';

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		pageTitle: 'Filmer',
		baseTheme: 'brand',
		pageTheme: '',
		flash: {
			type: null,
			message: null,
			displayed: false
		},
		uploadCounter: 0,
	},
	mutations: {
		setPageTitle(state, newTitle) {
			state.pageTitle = newTitle;
		},
		setBaseTheme(state, newBaseTheme) {
			state.baseTheme = newBaseTheme;
		},
		setPageTheme(state, newPageTheme) {
			state.pageTheme = newPageTheme;
		},
		setFlash(state, flash) {

			state.flash = {
				type: flash.type,
				message: flash.message,
				timeout: flash.timeout || 0,
				displayed: false
			}
			console.log(state.flash )
		},
		flashDisplayed(state) {
			state.flash.displayed = true;
		},
		addUploadInProgress(state) {
			state.uploadCounter++;
		},
		removeUploadInProgress(state) {
			//Dont allow it to drop below zero
			if (state.uploadCounter !== 0) {
				state.uploadCounter--;
			}
		}
	},
	actions: {
	},
	modules: {
		auth
	}
})
