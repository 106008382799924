
import Dashboard from '@/views/creative/Dashboard';

const routes = [
	{
		path: '/creative',
		name: 'creativeDashboard',
		component: Dashboard,
		meta: {
			pageTitle: 'Dashboard',
			authFor: 'creative'
		}
	},
	{
		path: '/creative/briefs',
		name: 'creativeBriefs',
		meta: {
			pageTitle: 'Briefs',
			authFor: 'creative',
			pageTheme: 'green',
		},
		component: () => import(/* webpackChunkName: "creativeBriefs" */ '@/views/creative/Briefs.vue')
	},
	{
		path: '/creative/brief/:id',
		name: 'creativeBrief',
		meta: {
			pageTitle: 'Brief',
			authFor: 'creative',
			pageTheme: 'green',
		},
		component: () => import(/* webpackChunkName: "creativeBrief" */ '@/views/creative/Brief.vue')
	},
	{
		path: '/creative/treatments',
		name: 'creativeTreatments',
		meta: {
			pageTitle: 'Treatments',
			authFor: 'creative',
			pageTheme: 'yellow',
		},
		component: () => import(/* webpackChunkName: "creativeTreatments" */ '@/views/creative/Treatments.vue')
	},
	{
		path: '/creative/treatment/:id',
		name: 'creativeTreatment',
		meta: {
			pageTitle: 'Treatment',
			authFor: 'creative',
			pageTheme: 'yellow',
		},
		component: () => import(/* webpackChunkName: "creativeTreatment" */ '@/views/creative/Treatment.vue')
	},
	{
		path: '/creative/update-treatment/:id',
		name: 'creativeUpdateTreatment',
		meta: {
			pageTitle: 'Update Treatment',
			authFor: 'creative',
			pageTheme: 'yellow',
		},
		component: () => import(/* webpackChunkName: "creativeUpdateTreatment" */ '@/views/creative/UpdateTreatment.vue')
	},
	{
		path: '/creative/help/',
		name: 'creativeHelp',
		meta: {
			pageTitle: 'Help',
			authFor: 'creative',
			pageTheme: 'green',
		},
		component: () => import(/* webpackChunkName: "brandTreatment" */ '@/views/creative/Help.vue')
	},
]

export default routes
