const routes = [
	{
		path: '/register',
		name: 'register',
		meta: {
			pageTitle: 'Register'
		},
		component: () => import(/* webpackChunkName: "register" */ '@/views/auth/Register.vue')
	},
	{
		path: '/login',
		name: 'login',
		meta: {
			pageTitle: 'Login'
		},
		component: () => import(/* webpackChunkName: "login" */ '@/views/auth/Login.vue'),
	},
	{
		path: '/forgot-password',
		name: 'forgotPassword',
		meta: {
			pageTitle: 'Forgot Password'
		},
		component: () => import(/* webpackChunkName: "forgotPassword" */ '@/views/auth/ForgotPassword.vue')
	},
	{
		path: '/reset-password/:token',
		name: 'resetPassword',
		meta: {
			pageTitle: 'Reset Your Password'
		},
		component: () => import(/* webpackChunkName: "forgotPassword" */ '@/views/auth/ResetPassword.vue')
	},
	{
		path: '/verify',
		name: 'verify',
		meta: {
			pageTitle: 'Verify Email Address'
		},
		component: () => import(/* webpackChunkName: "verify" */ '@/views/auth/Verify.vue')
	},

]

export default routes