import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'
import store from '@/store'
import Vuetify from '../plugins/vuetify';

//Routes
import authRoutes from '@/router/auth'
import brandRoutes from '@/router/brand'
import creativeRoutes from '@/router/creative'

Vue.use(VueRouter)
Vue.use(Meta)

const baseRoutes = [
	{
		path: '/',
		name: 'root',
		beforeEnter: (to, from, next) => {
			if(store.getters['auth/user'].type == 'Brand') {
				return next({
					name: 'brandDashboard'
				})
			}
			else if(store.getters['auth/user'].type == 'Creative') {
				return next({
					name: 'creativeDashboard'
				})
			}
		}
	},
	{
		path: '/projects',
		name: 'projects',
		meta: {
			pageTitle: 'Projects',
			pageTheme: 'orange',
		},
		component: () => import(/* webpackChunkName: "projects" */ '../views/Projects.vue')
	},
	{
		path: '/project/:id',
		name: 'projectDetail',
		meta: {
			pageTitle: 'Projects',
			pageTheme: 'orange',
		},
		component: () => import(/* webpackChunkName: "projectDetail" */ '../views/Project.vue')
	},
	{
		path: '/profile/:id?',
		name: 'profile',
		meta: {
			pageTitle: 'Profile'
		},
		beforeEnter(to, from, next) {
			next((vm) => {
				if (vm.$router) {
					vm.$router.referer = from;
				}
			});
		},
		component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue')
	},
	{
		path: '/milestones',
		name: 'milestones',
		meta: {
			pageTitle: 'All Milestones'
		},
		component: () => import(/* webpackChunkName: "milestones" */ '../views/Milestones.vue')
	},
	{
		path: '/payments',
		name: 'payments',
		meta: {
			pageTitle: 'All Payments'
		},
		component: () => import(/* webpackChunkName: "payments" */ '../views/Payments.vue')
	},
	{
		path: '/unanswered-questions',
		name: 'unansweredQuestions',
		meta: {
			pageTitle: 'All Unanswered Questions'
		},
		component: () => import(/* webpackChunkName: "unansweredQuestions" */ '../views/UnansweredQuestions.vue')
	},
	{
		path: '/edit-profile',
		name: 'editProfile',
		meta: {
			pageTitle: 'Edit Profile'
		},
		component: () => import(/* webpackChunkName: "editProfile" */ '../views/EditProfile.vue')
	},
	{
		path: '/manage-team',
		name: 'manageTeam',
		meta: {
			pageTitle: 'Manage Team'
		},
		component: () => import(/* webpackChunkName: "editProfile" */ '../views/ManageTeam.vue')
	},
	{
		path: '/about',
		name: 'about',
		meta: {
			pageTitle: 'About'
		},
		component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
	},
	{
		path: '/impersonate',
		name: 'impersonate',
		meta: {
			pageTitle: 'Switch User',
			authFor: 'admin',
		},
		component: () => import(/* webpackChunkName: "impersonate" */ '../views/Impersonate.vue')
	},
	{
		path: '/switch-brand',
		name: 'switch-brand',
		meta: {
			pageTitle: 'Switch Brand'
		},
		component: () => import(/* webpackChunkName: "switchBrand" */ '../views/SwitchBrand.vue')
	},
	{
		path: '/unsubscribe/:id',
		name: 'unsubscribe',
		meta: {
			pageTitle: 'Unsubscribe'
		},
		component: () => import(/* webpackChunkName: "unsubscribe" */ '../views/Unsubscribe.vue')
	},
	{
		path: "*",
		name: 'fourOhFour',
		meta: {
			pageTitle: '404'
		},
		component: () => import(/* webpackChunkName: "fourOhFour" */ '../views/FourOhFour.vue')
	}
]

let routes = [];

routes = routes.concat(baseRoutes, authRoutes, brandRoutes, creativeRoutes);

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior () {
		return { x: 0, y: 0 }
	}
})

router.beforeEach((to, from, next) => {

	//All pages require auth except login and register and forgot / reset password
	if (to.name != 'login' && to.name != 'register' && to.name != 'forgotPassword' && to.name != 'resetPassword' && to.name != 'unsubscribe') {
		//Allow to show login page after email verification of a logged in user
		if (to.query.verifyURL) {
			//log user out so they can re log in after email validation
			store.commit('SET_TOKEN', null);
			store.commit('SET_USER', null);
		}

		if (!store.getters['auth/authenticated']) {
			return next({
				name: 'login'
			})
		}

	}

	//If email address is not validated, redirect to email validation page
	if (store.getters['auth/authenticated'] && !store.getters['auth/user'].email_verified_at && to.name != 'verify' && to.name != 'login') {
		return next({
			name: 'verify'
		})
	}

	//Auth for brand pages
	if (to.meta.authFor == 'brand') {
		//Don't allow creatives in brand section
		if (store.getters['auth/user'].type != 'Brand') {
			return next({
				name: 'root'
			})
		}
		//Redirect to create brand page if user has no brand
		if (!store.getters['auth/user'].brand && to.name != 'addBrand') {
			return next({
				name: 'addBrand'
			})
		}

		// set base theme
		store.commit('setBaseTheme', 'brand');
	}

	//Auth for creative pages
	if (to.meta.authFor == 'creative') {
		//Don't allow creatives in brand section
		if (store.getters['auth/user'].type != 'Creative') {
			return next({
				name: 'root'
			})
		}

		// set base theme
		store.commit('setBaseTheme', 'creative');

	}

	if (to.meta.authFor == 'admin') {
		//Don't allow non admins here
		if (store.getters['auth/user'].admin !== 1) {
			return next({
				name: 'root'
			})
		}
	}

	Vuetify.framework.theme.dark = true;

	store.commit('setPageTheme', to.meta.pageTheme);
	store.commit('setPageTitle', to.meta.pageTitle);
	next();
});

router.afterEach(() => {
	store.commit('flashDisplayed');
})

export default router
