<template>
	<v-card height="100%" width="100%" flat class="ps-treatment-card">
		<v-img class="white--text align-end" :aspect-ratio="2/1" :src="coverImg" eager>
			<template v-slot:placeholder>
				<v-row class="fill-height ma-0" align="center" justify="center">
					<v-progress-circular indeterminate color="grey"></v-progress-circular>
				</v-row>
			</template>
		</v-img>
		<v-card-title class="psgreydark--text title text-uppercase font-weight-black break-word">{{ treatment.headline }}</v-card-title>
		<v-card-subtitle class="pb-0 mb-4 font-weight-bold">{{treatment.brief.project_name}}</v-card-subtitle>


		<v-card-text class="py-2 flex-grow-1">
			<v-row v-if="user && user.type == 'Creative'">
				<v-col class="py-0 font-weight-bold">Brand:</v-col>
				<v-col class="py-0 font-weight-medium">{{ treatment.brand_name }}</v-col>
			</v-row>
			<v-row v-if="user && user.type == 'Brand'">
				<v-col class="py-0 font-weight-bold">Creative:</v-col>
				<v-col class="py-0 font-weight-medium">{{ treatment.creative }}</v-col>
			</v-row>
			<v-row v-if="user && user.type != 'Brand'">
				<v-col class="py-0 font-weight-bold">Category:</v-col>
				<v-col class="py-0 font-weight-medium">{{ treatment.brief.category }}</v-col>
			</v-row>
			<v-row v-if="user && user.type != 'Brand'">
				<v-col class="py-0 font-weight-bold">Budget:</v-col>
				<v-col class="py-0 font-weight-medium">R {{ budget }}</v-col>
			</v-row>
			<v-row>
				<v-col class="py-0 font-weight-bold">Selection Date:</v-col>
				<v-col class="py-0 font-weight-medium">{{ treatment.brief.selection_date | moment("Do MMMM YYYY") }}</v-col>
			</v-row>
			<v-row>
				<v-col class="py-0 font-weight-bold">Current Status:</v-col>
				<v-col class="py-0 font-weight-medium">{{ treatment.status }}</v-col>
			</v-row>
			<v-row v-if="user && user.type == 'Brand' && treatment.brief.tier['treatment_approval_required'] == 1">
				<v-col class="py-0 font-weight-bold">Filmer Approved:</v-col>
				<v-col class="py-0 font-weight-medium">{{ treatment.filmer_status > 0 ? 'Yes' : 'No' }}</v-col>
			</v-row>
		</v-card-text>

		<v-card-actions>
			<v-row justify="space-between">

				<v-col class="px-0">
					<v-btn v-if="user && user.type == 'Brand'" color="primary" target="_blank" :href="`/brand/treatment/${treatment.id}`">
						View
					</v-btn>
					<v-btn v-else color="primary" target="_blank" :href="`/creative/treatment/${treatment.id}`">
						View
					</v-btn>
				</v-col>

				<!-- <v-col class="text-right">
					<span class="font-weight-bold body-2">{{ treatment.status }}</span>
					<v-tooltip top>
						<template v-slot:activator="{ on }">
							<v-btn v-if="treatment.status === 'Awaiting approval'" color="primary" fab small v-on="on">
								<v-icon>fa-exclamation</v-icon>
							</v-btn>

							<v-btn v-if="treatment.status === 'Approved'" color="primary" fab small v-on="on">
								<v-icon>fa-check</v-icon>
							</v-btn>

							<v-btn v-if="treatment.status === 'Awarded'" color="primary" fab small v-on="on">
								<v-icon>fa-check</v-icon>
							</v-btn>

							<v-btn v-else-if="treatment.status === 'Rejected'" color="primary" fab small v-on="on">
								<v-icon>fa-times</v-icon>
							</v-btn>

							<v-btn v-else-if="treatment.status === 'Submitted'" color="primary" fab small v-on="on">
								<v-icon>fa-question</v-icon>
							</v-btn>

							<v-btn v-else-if="treatment.status === 'Shortlist'" color="primary" fab small v-on="on">
								<v-icon>fa-star</v-icon>
							</v-btn>
						</template>

					</v-tooltip>
				</v-col>-->
			</v-row>

		</v-card-actions>
	</v-card>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	props: {
		treatment: Object,
		detailed: Boolean
	},
	computed: {
		...mapGetters({
			user: 'auth/user'
		}),

		coverImg() {
			if (this.treatment.image) {
				return this.treatment.image;
			}
			else {
				return `https://placeimg.com/420/200/any?v=${this.treatment.id}`;
			}
		},

		routeName() {
			if (this.user && this.user.type === 'Creative') {
				return 'creativeTreatment';
			}
			else {
				return 'brandTreatment';
			}
		},

		routeParam() {
			return this.treatment.id;
		},

		budget() {
			if (this.user.type == 'Brand') {
				return this.treatment.brief.budget;
			}
			else {
				return this.treatment.brief.project_budget
			}
		}
	}
}
</script>

<style>

</style>
