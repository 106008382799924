<template>
    <v-row>
        <v-col cols="12">
            <div class="mb-5">
                <ps-carousel
                    :slides="cards"
                    :customOptions="listCardCarouselOptions"
                    initTimeout
                    card="list"
                ></ps-carousel>
            </div>

            <div class="mb-5" v-if="openTreatments.length">
                <ps-carousel
                    title="New Treatments for Review"
                    :slides="openTreatments"
                    card="treatment"
                ></ps-carousel>
            </div>

            <div class="mb-5" v-if="myProjects.length">
                <ps-carousel
                    title="My Projects"
                    :slides="myProjects"
                    card="project"
                ></ps-carousel>
            </div>

            <div class="mb-5" v-if="openBriefs.length">
                <ps-carousel
                    title="My Open Briefs"
                    :slides="openBriefs"
                    card="brief"
                ></ps-carousel>
            </div>

            <v-container
                v-if="
                    !openBriefs.length &&
                    !myProjects.length &&
                    !openTreatments.length
                "
            >
                <v-row>
                    <v-col cols="12" md="8">
                        <strong
                            >Welcome to your Filmer.tv Client Dashboard!</strong
                        >
                        <br /><br />
                        Here you will find a quick overview including any
                        'Upcoming Milestones' that are relevant to you, 'Recent
                        Payment' notifications as well as any 'Unanswered
                        Questions' that have been asked of you. Once you have
                        submitted a brief, received a treatment to review or
                        have a live project, they will also be listed in this
                        space here.
                        <br /><br />
                        Why don't you go ahead and

                        <router-link
                            :to="{ name: 'brandAddBrief' }"
                            class="u-text-decoration-none"
                            >launch your first brief here</router-link
                        >
                        or by accessing the Briefs section in the Menu bar on
                        the left. <br /><br />
                        Filmer will review and validate all briefs with you
                        before they go live.
                        <br /><br />
                        <v-btn
                            class="my-4"
                            large
                            color="primary"
                            :to="{ name: 'brandAddBrief' }"
                            >Create a brief</v-btn
                        >
                    </v-col>
                    <v-col cols="12" md="4">
                        <a
                            href="/documents/Filmer-how-to-write-a-video-brief.pdf"
                            target="_blank"
                        >
                            <v-img
                                src="~@/assets/how-to-brief.png"
                                max-dwidth="300px"
                            ></v-img>
                        </a>
                    </v-col>
                </v-row>
            </v-container>
        </v-col>
    </v-row>
</template>

<script>
import psCarousel from '@/components/Carousel.vue';
import colors from 'vuetify/lib/util/colors';
import { mapGetters } from 'vuex';

export default {
    name: 'dashboard',

    components: {
        psCarousel,
    },
    data() {
        return {
            selected: [],
            cards: {
                upcomingMilestones: {
                    label: 'Upcoming Milestones',
                    colour: 'psgreydark',
                    items: [],
                    link: { name: 'milestones' },
                },
                recentPayments: {
                    label: 'Recent Payments',
                    colour: 'psgreydark',
                    items: [],
                    link: { name: 'payments' },
                },
                unansweredQuestions: {
                    label: 'Unanswered Questions',
                    colour: 'psgreydark',
                    items: [],
                    link: { name: 'unansweredQuestions' },
                },
            },
            listCardCarouselOptions: {
                centeredSlides: false,
                initialSlide: 0,
                watchOverflow: true,
                init: false,
				slidesPerView: 1,
                breakpoints: {
                    600: {
                        slidesPerView: 2,
                        spaceBetween: 0,
                    },
                    960: {
                        slidesPerView: 3,
                        spaceBetween: 24,
                    },
                },
            },
            openTreatments: [],
            openBriefs: [],
            myProjects: [],
            colors: colors,
        };
    },
    computed: {
        ...mapGetters({
            // map `this.doneCount` to `this.$store.getters.doneTodosCount`
            authenticated: 'auth/authenticated',
            user: 'auth/user',
        }),
    },
    methods: {},
    mounted() {
        this.$axios
            .get('/me/briefs?status=published')
            .then((response) => (this.openBriefs = response.data.data));

        this.$axios
            .get('/me/projects')
            .then((response) => (this.myProjects = response.data.data));

        this.$axios
            .get(
                `/treatments?status=submitted&brand=${this.user.brand}&unseen=true`
            )
            .then((response) => (this.openTreatments = response.data.data));

        this.$axios
            .get(`/me/milestones?latest=true`)
            .then(
                (response) =>
                    (this.cards.upcomingMilestones.items = response.data.data)
            );

        this.$axios
            .get(`/me/payments?limit=4`)
            .then(
                (response) =>
                    (this.cards.recentPayments.items = response.data.data)
            );

        this.$axios
            .get(`/me/unanswered-questions?limit=4`)
            .then(
                (response) =>
                    (this.cards.unansweredQuestions.items = response.data.data)
            );
    },
};
</script>
