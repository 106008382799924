<template>
	<div class="ps-list-card">
		<div class="ps-list-card__header">
			<h2 class="headline font-weight-black text-uppercase text-center psgreydark--text">{{ title }}</h2>
		</div>
		<v-card class="ps-list-card__card d-flex flex-column" height="calc(100% - 56px)">

			<div v-if="items.length" class="d-flex flex-column" style="height:100%">
				<v-list class="ps-list-card__list flex-grow-1">
					<v-list-item-group>
						<v-list-item v-bind="itemLink(item)" class="ps-list-card__list-item" v-for="item in items" :key="item.title" :to="getDetailRoute(item)">
							<ps-avatar v-if="item.user && item.user.avatar" :image="item.user.avatar" :border-color="item.user.brand === 1 ? 'psblue' : 'pspink'" size="small"></ps-avatar>

							<v-list-item-content v-if="item.title" class="mr-5">
								<v-list-item-title class="psgreydark--text font-weight-bold body-1" v-text="item.title"></v-list-item-title>
							</v-list-item-content>

							<v-list-item-content v-if="item.description" class="mr-5">
								<v-list-item-title class="psgreydark--text font-weight-bold body-1" v-text="item.description"></v-list-item-title>
							</v-list-item-content>

							<v-list-item-content v-if="item.content" class="mr-5">
								<v-list-item-title class="psgreydark--text font-weight-bold body-1" v-text="item.content"></v-list-item-title>
							</v-list-item-content>

							<v-list-item-content v-if="item.amount" class="mr-5">
								<v-list-item-title class="psgreydark--text font-weight-bold body-1">R{{ item.amount }}</v-list-item-title>
							</v-list-item-content>

							<v-list-item-content class="flex-grow-0 flex-shrink-0 u-flex-auto">
								<v-list-item-title class="psgreydark--text font-weight-bold body-1" v-text="item.date"></v-list-item-title>
							</v-list-item-content>

						</v-list-item>
					</v-list-item-group>
				</v-list>

				<v-card-actions v-if="to" class="ps-list-card__actions d-flex justify-center">
					<v-btn color="primary" :to="to" large>View All <v-icon right class="mr-0 ps-greydark--text">fas fa-angle-right</v-icon></v-btn>
				</v-card-actions>
			</div>
			<v-card-title class="ma-auto body-1 psgreydark--text text-uppercase font-weight-xtrabold" v-else>No items yet</v-card-title>
		</v-card>
	</div>
</template>

<script>
import psAvatar from "@/components/Avatar.vue";
import { mapGetters } from 'vuex';

export default {
	name: 'psListCard',
	components: {
		psAvatar
	},
	props: {
		title: String,
		items: Array,
		to: Object
	},
	methods: {
		itemLink(item) {
			var routeName;

			if (item.questionable_content) {
				if (this.$route.meta.authFor == 'creative') {
					routeName = 'creativeTreatment';
				}
				else if (this.$route.meta.authFor == 'brand') {
					routeName = 'brandBrief';
				}

				return {
					to: {
						name: routeName,
						params: {
							id: item.questionable_content.id
						}
					}
				}
			}

			return {};
		},
		getDetailRoute(item) {
			if (item.project_id) {
				return {
					name: 'projectDetail',
					params: {
						id: item.project_id
					}
				}
			}
			else {
				if (this.user.type == 'Brand') {
					return {
						name: 'brandBrief',
						params: {
							id: item.questionable_id
						}
					}
				}
				else {
					return {
						name: 'creativeTreatment',
						params: {
							id: item.questionable_id
						}
					}
				}
			}
		},
	},
	computed: {
		...mapGetters({
			// map `this.doneCount` to `this.$store.getters.doneTodosCount`
			authenticated: 'auth/authenticated',
			user: 'auth/user'
		}),

		// avatarColor() {
		// 	return this.$vuetify.theme.dark ? this.$vuetify.theme.themes.dark[this.colour] : this.$vuetify.theme.themes.light[this.colour];
		// }
	}
}
</script>

<style lang="scss" scoped>
	.ps-list-card {
		box-shadow: $custom-shadow-elevation-1;
		height: 100%;

		&__card {
			box-shadow: none;
		}
		&__header {
			padding-top: $spacer-3;
			padding-bottom: $spacer-3;
			background-color: map-get($material-dark-elevation-colors, '1');
		}
		&__list {
			padding: 0;
		}
		&__list-item {
			min-height: 62px;

			&:nth-child(odd) {
				background-color: map-get($material-dark-elevation-colors, '2');
			}
		}
		&__actions {
			background-color: map-get($material-dark-elevation-colors, '2');
		}
	}

</style>
