<template>

		<v-snackbar
			class="ps-flash-message"
			:class="`ps-flash-message--${color}`"
			v-model="showFlash"
			top
			multi-line
			width="462"
			transition="fade-transition"
			elevation="2"
			:timeout="`${time}`"
		>

			<div class="message">{{ $store.state.flash.message }}</div>
			<v-btn
					icon
					color="psgreydark"
					class="flash-close-button"
					@click="close()"
			>
				<v-icon color="#333">mdi-close</v-icon>
			</v-btn>
		</v-snackbar>

</template>

<script>
export default {
	data: () => ({
		type: null,
		message: null
	}),
	computed: {
		showFlash: {

			get() {
				if (this.$store.state.flash.type && this.$store.state.flash.message && !this.$store.state.flash.displayed) {
					return true;
				}
				return false;
			},

			set(value) {
				return value;
			}

		},
		color() {
			return this.$store.state.flash.type;
		},
		time() {
			return this.$store.state.flash.timeout || -1;
		}
	},
	methods: {
		close () {
			this.$store.commit('setFlash', {
				type: null,
				message: null
			});
		}
	}
}
</script>

<style lang="scss" scoped>
	.ps-flash-message {
		top: 40%;
		::v-deep {
			.flash-close-button {
				background-color: inherit !important;
			}
			.v-snack__wrapper {
				color: #333;
				border-radius: 5px;
				background-color: #0DAEEF;
				align-items: flex-center;
				padding: 15px;
			}
			.v-snack__content {
				border-radius: 5px;
				background-color: transparent;
				padding-right: 24px;
			}

			.v-snack__action {
				margin-right: 0 !important;
			}

			.flash-close-button {
				position: absolute;
				top: 10px;
				right: 10px;
			}

			.v-btn--icon.v-size--default {
				width: auto;
				height: auto;
			}

			.v-btn--icon.v-size--default .v-icon {
				color: #ffffff!important;
			}
			.message {
				line-height: 1.4;
				font-weight: 600;
				font-size: 16px;
				color: #ffffff;
			}

		}
	}

	.ps-flash-message--error {
		::v-deep {

			.v-snack__content {
				background-color: #0DAEEF;
			}

		}
	}

	.ps-flash-message--success {
		::v-deep {

			.v-snack__content {
				background-color: #0DAEEF;



			}
		}
	}
</style>





