<template>
	<div class="ps-carousel">
		<ps-section-header v-if="title">{{ title }}</ps-section-header>
		<v-row class="ps-carousel__wrap">
			<div class="ps-carousel__control ps-carousel__control--prev text-center">
				<v-icon :class="`swiper-prev swiper-prev--${this._uid}`" large color="secondary" slot="button-prev">fas fa-angle-left</v-icon>
			</div>
			<v-col cols="12">
				<swiper  v-bind="computedOptions" ref="psCarousel">
					<swiper-slide v-for="slide in slides" :key="slide.id">
						<ps-list-card v-if="card === 'list'" :title="slide.label" :items="slide.items"  :to="slide.link"></ps-list-card>

						<ps-brief-card v-if="card === 'brief'" :brief="slide"></ps-brief-card>
						<ps-treatment-card v-if="card === 'treatment'" :treatment="slide" detailed></ps-treatment-card>
						<ps-project-card v-if="card === 'project'" :project="slide" detailed></ps-project-card>
						<ps-video-card v-if="card === 'video'" :cover="slide.cover" :embedId="slide.embedId" :source="slide.source" :title="slide.title" :subtitle="slide.subtitle"></ps-video-card>
					</swiper-slide>
				</swiper>
			</v-col>
			<div class="ps-carousel__control ps-carousel__control--next text-center">
				<v-icon :class="`swiper-next swiper-next--${this._uid}`" large color="secondary" slot="button-next">fas fa-angle-right</v-icon>
			</div>
		</v-row>
	</div>
</template>

<script>
import psBriefCard from "@/components/BriefCard.vue"
import psListCard from "@/components/ListCard.vue"
import psSectionHeader from "@/components/SectionHeader.vue"
import psTreatmentCard from "@/components/TreatmentCard.vue"
import psProjectCard from "@/components/ProjectCard.vue"
import psVideoCard from "@/components/VideoCard.vue"
import { Navigation, Pagination } from 'swiper'
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import 'swiper/swiper-bundle.css'

SwiperCore.use([Navigation, Pagination])

export default {
	name: 'psCarousel',
	components: {
		psBriefCard,
		psListCard,
		psSectionHeader,
		psTreatmentCard,
		psVideoCard,
		Swiper,
		SwiperSlide,
		psProjectCard
	},
	props: {
		card: String,
		customOptions: Object,
		hideControlsBreakpoint: String,
		initTimeout: Boolean,
		slides: null,
		title: String,
	},
	data() {
		return {
			options: {
				slidesPerView: 3,
				spaceBetween: 24,
				centeredSlides: true,
				initialSlide: 1,
				navigation: {
					nextEl: `.swiper-next--${this._uid}`,
					prevEl: `.swiper-prev--${this._uid}`
				},
				breakpoints: {
					600: {
						slidesPerView: 1,
						spaceBetween: 0,
					},
					960: {
						slidesPerView: 2,
						spaceBetween: 24,
					}
				}
			},
		}
	},
	computed: {
		computedOptions() {
			let options = this.options;
			if(this.customOptions) {
				for (let [key, value] of Object.entries(this.customOptions)) {
					options[key] = value;
				}
			}

			return options;
		},
		swiper() {
			return this.$refs.psCarousel.swiper;
		},
	},
	mounted() {
		let $this = this;

	  if (this.initTimeout) {
			// NOTE: temporary hack until I can figure out why slides are getting wrong width for listcards on page load
			setTimeout(function() {
				$this.swiper.init();
			}, 1)
		}
	}
}

</script>

<style lang="scss">
	.ps-carousel {
		&__wrap {
			position: relative;
			justify-content: center;
		}
		&__control {
			order: 1;
			margin: 0 $spacer-3;
			@media #{$screen-md-up} {
				position: absolute;
				align-self: center;
				order: 0;
				margin: 0;
			}
			&--hide-desktop {
				@media #{$screen-md-up} {
					display: none;
				}
			}
			&--prev {
				@media #{$screen-md-up} {
					left: -50px;
				}
			}
			&--next {
				@media #{$screen-md-up} {
					right: -50px;
				}
			}
			.v-icon.v-icon::after {
				background-color: transparent;
			}
		}

		.swiper-slide {
			height: auto;

			&:not(.swiper-slide-active) {
				.ps-treatment-card,
				.ps-brief-card {
					&.v-card {
						transform: scale(0.8);
						filter: grayscale(100%);
						transition: transform 400ms ease-in-out, filter 600ms ease;
						.v-card {
							&__title {
								transform: scale(1.25);
								transform-origin: left;
								filter: brightness(0.4);
							}
							&__subtitle {
								display: none;
							}
							&__text {
								display: none;
							}
							&__actions {
								display: none;
							}
						}
					}
				}
			}
		}
		.swiper-button-disabled {
			filter: grayscale(100%) brightness(0.5);
		}
		.swiper-button-lock {
			display: none;
		}

	}

</style>
