<template>
	<v-list-item-avatar :color="borderColor" :size="avatarSize">
		<v-img :src="image"></v-img>
	</v-list-item-avatar>
</template>

<script>
export default {
	name: "psAvatar",
	props: {
		image: {
			type: String,
			default: null,
		},
		borderColor: String,
		size: {
			type: String,
			default: "small"
		},
	},
	computed: {
		avatarSize() {
			if (this.size === 'full') {
				return 100 + '%';
			}
			if (this.size === 'large') {
				return 100;
			}
			else if (this.size === 'medium') {
				return 60;
			}
			return 40;
		}
	}
}
</script>

<style lang="scss" scoped>
	.v-avatar {
		border-width: 3px;
		border-style: solid;
	}
</style>