<template>
	<v-row>
		<v-col cols="12">
			<div class="mb-5">
				<ps-carousel :slides="cards" :customOptions="listCardCarouselOptions" initTimeout card="list"></ps-carousel>
			</div>

			<div class="mb-5" v-if="myTreatments.length">
				<ps-carousel title="Submitted Treatments" :slides="myTreatments" card="treatment"></ps-carousel>
			</div>

			<div class="mb-5" v-if="myProjects.length">
				<ps-carousel title="My Projects" :slides="myProjects" card="project"></ps-carousel>
			</div>

			<v-container v-if="!myTreatments.length && !myProjects.length">
				<v-row>
					<v-col cols="12" md="8">
						<strong>Welcome to your Filmer.tv Creative Dashboard!</strong><br><br>Here you will find a quick overview including any 'Upcoming Milestones' that are relevant to you, 'Recent Payment' notifications as well as any 'Unanswered Questions' that have been asked of you. Once you have submitted a treatment or have a live project, it will also be listed in this space.
						<br><br>
						But most importantly as a creative, it is <router-link :to="{ name: 'editProfile' }" class="u-text-decoration-none">essential for you to fill out your profile</router-link>, this is very important when clients start to view your treatments.
						<br><br>
						<v-btn class="my-4" color="primary" large :to="{ name: 'editProfile' }">Complete your profile</v-btn>
					</v-col>
					<v-col cols="12" md="4">
						<a href="/documents/Filmer-how-to-write-a-treatment-fixed.pdf" target="_blank">
							<v-img src="~@/assets/how-to-treatment.png"  max-dwidth="300px" ></v-img>
						</a>
					</v-col>
				</v-row>
			</v-container>

		</v-col>
	</v-row>
</template>

<script>
	import { mapGetters } from 'vuex';
	import psCarousel from "@/components/Carousel.vue"

	export default {
		name: 'creativeDashboard',

		components: {
			psCarousel
		},
		data () {
			return {
				cards: {
					upcomingMilestones: {
						label: 'Upcoming Milestones',
						colour: 'psyellow',
						items: [],
						link: { name: 'milestones'}
					},
					recentPayments: {
						label: 'Recent Payments',
						colour: 'psgreen',
						items: [],
						link: { name: 'payments'}
					},
					unansweredQuestions: {
						label: 'Unanswered Questions',
						colour: 'primary',
						items: [],
						link: { name: 'unansweredQuestions' }
					}
				},
				listCardCarouselOptions: {
					centeredSlides: false,
					initialSlide: 0,
					watchOverflow: true,
					init: false,
				},
				myTreatments: [],
				myProjects: [],
			}
		},
		computed: {
			...mapGetters({
				// map `this.doneCount` to `this.$store.getters.doneTodosCount`
				authenticated: 'auth/authenticated',
				user: 'auth/user'
			}),
		},
		methods: {
			getColor(color) {
				return this.$vuetify.theme.themes.light[color];
			}
		},
		mounted () {
			this.$axios.get('/me/treatments?status=awaiting_approval,submitted,shortlist,awarded,rejected').then(response => (this.myTreatments = response.data.data));

			this.$axios.get('/me/projects').then(response => (this.myProjects = response.data.data));

			this.$axios.get(`/me/milestones?latest=true`)
			.then(response => (this.cards.upcomingMilestones.items = response.data.data));

			this.$axios.get(`/me/payments?limit=4`)
			.then(response => (this.cards.recentPayments.items = response.data.data));

			this.$axios.get(`/me/unanswered-questions?limit=4`)
			.then(response => (this.cards.unansweredQuestions.items = response.data.data));
		}
	}
</script>

<style lang="scss">

</style>
