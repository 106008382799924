<template>
	<nav class="ps-navbar">
		<v-navigation-drawer class="side-nav" dark v-model="drawer" app :right="$vuetify.breakpoint.mdAndDown" hide-overlay>
			<v-app-bar-nav-icon class="float-right d-lg-none" @click.stop="drawer = !drawer">
				<v-icon color="white">mdi-close</v-icon>
			</v-app-bar-nav-icon>
			<ps-user v-if="authenticated"></ps-user>

			<v-list v-else flat>
				<v-list-item class="mb-4" router href="https://filmer.tv" target="_blank">
					<!-- <v-img src="@/assets/filmer_logo.svg" max-width="100%" contain></v-img> -->
				</v-list-item>
				<v-list-item class="pl-8" router :to="{name : 'login'}" exact>
					<!-- <v-list-item-action class="mr-4">
						<v-icon medium>mdi-login</v-icon>
					</v-list-item-action> -->
					<v-list-item-content>
						<v-list-item-title class="title font-weight-xtrabold">Login</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item class="pl-8" router :to="{name : 'register'}" exact>
					<!-- <v-list-item-action class="mr-4">
						<v-icon medium>mdi-login</v-icon>
					</v-list-item-action> -->
					<v-list-item-content>
						<v-list-item-title class="title font-weight-xtrabold">Register</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
			<v-divider dark class="menu-divider my-5"></v-divider>
			<v-list tile nav flat>
				<v-list-item class="pl-8" router v-for="item in menuItems" v-bind:key="item.title" :to="{name : item.route}" exact>
					<v-list-item-icon class="mr-4">
						<v-icon medium>{{ item.icon }}</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title class="title font-weight-xtrabold ">{{ item.title }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>




				<v-list-item class="pl-8" router v-if="user && user.type === 'Brand' && myBrands.length > 1" :to="{name : 'switch-brand'}" exact>
					<v-list-item-icon class="mr-4">

						<v-icon medium>fas fa-toggle-off</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title class="title font-weight-xtrabold ">Switch brand</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
			<v-list-item class="pl-8" router v-if="user && isImpersonating" @click.prevent="stopImersonating" exact>
					<v-list-item-icon class="mr-4">
						<v-icon medium>fa-users</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title class="title font-weight-xtrabold ">Switch Back</v-list-item-title>
					</v-list-item-content>
				</v-list-item>


				<v-list-item class="pl-8" router v-if="user && user.admin === 1 && !isImpersonating" :to="{name : 'impersonate'}" exact>
					<v-list-item-icon class="mr-4">
						<v-icon medium>fa-users</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title class="title font-weight-xtrabold ">Switch User</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

			<v-row v-if="authenticated" class="pl-2" style="position: fixed; bottom: 10;">
				<a href="/" target="_blank">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1113.55 238.6" style="position: fixed; max-width: 200px; left: 10%; margin-top: 10px; bottom: 20">
						<g id="Layer_2" data-name="Layer 2">
							<g id="Layer_1-2" data-name="Layer 1">
								<path fill="#333333" class="cls-1" d="M705.79,54.49v176.4a7.71,7.71,0,0,1-7.71,7.71H643.59a7.71,7.71,0,0,1-7.71-7.71V142.12Z"/>
								<path fill="#333333" class="cls-1" d="M425.48,53.87v176.4a7.71,7.71,0,0,0,7.71,7.71h54.49a7.71,7.71,0,0,0,7.71-7.71V141.5Z"/>
								<path fill="#333333" class="cls-2" d="M1082.57,207.87l-5.45,30.49H1065.6l-.52-3.23L1062.65,220l-7.23-45.14v-2.12a3.48,3.48,0,0,1,3.48-3.47h14.43a3.66,3.66,0,0,1,.88.11,1.22,1.22,0,0,1,.27.09l.35.13.43.25a3.94,3.94,0,0,1,.45.36l.14.15a.7.7,0,0,1,.14.16,3.79,3.79,0,0,1,.3.4,4.64,4.64,0,0,1,.23.44l.16.39h0l.08.14v.05Z"/>
								<path fill="#333333" class="cls-2" d="M1092.29,171.73h0l-.08.14v.05l-10.59,65.58v.85h21.76l.51-3.23,2.43-15.15,7.23-45.14v-2.12a3.48,3.48,0,0,0-3.48-3.47h-14.42a3.68,3.68,0,0,0-.89.11,1.43,1.43,0,0,0-.27.09l-.34.13c-.16.08-.3.17-.43.25a2.73,2.73,0,0,0-.45.36l-.15.15-.14.16a3.79,3.79,0,0,0-.3.4c-.08.15-.16.29-.23.44"/>
								<path fill="#333333" class="cls-2" d="M1048.38,170.61v13.82a2.9,2.9,0,0,1-2.92,2.88h-15.64v47.41a2.9,2.9,0,0,1-2.93,2.88h-14a2.9,2.9,0,0,1-2.92-2.88V187.31H994.32a2.9,2.9,0,0,1-2.93-2.88V170.61a2.9,2.9,0,0,1,2.93-2.88h51.14a2.9,2.9,0,0,1,2.92,2.88"/>
								<path fill="#333333" class="cls-1" d="M405.63,237.27H270a7.72,7.72,0,0,1-7.71-7.72V175.07a7.71,7.71,0,0,1,7.71-7.71H405.63a7.72,7.72,0,0,1,7.72,7.71v54.48a7.72,7.72,0,0,1-7.72,7.72"/>\
								<path fill="#333333" class="cls-1" d="M158.67,71.15H8.54C3.82,71.15,0,67.7,0,63.44V9C0,4.69,3.82,1.24,8.54,1.24H158.67c4.71,0,8.53,3.45,8.53,7.71V63.44c0,4.26-3.82,7.71-8.53,7.71"/>
								<path fill="#333333" class="cls-1" d="M331.77,9.58V147.47a7.78,7.78,0,0,1-7.72,7.84H269.57a7.77,7.77,0,0,1-7.71-7.84V9.58a7.77,7.77,0,0,1,7.71-7.84h54.48a7.78,7.78,0,0,1,7.72,7.84"/>
								<path fill="#333333" class="cls-1" d="M249.13,13.29V225.21c0,6.66-3.46,12-7.72,12H186.93c-4.26,0-7.71-5.4-7.71-12.06V13.29c0-6.66,3.45-12,7.71-12h54.48c4.26,0,7.72,5.39,7.72,12"/>
								<path fill="#333333" class="cls-1" d="M705.59,8.53V35L677.16,70.72,610.6,154.4l-38.84,48.83a7.73,7.73,0,0,1-7.31,2.81l-.31-.07a7.68,7.68,0,0,1-3.94-2.62L521.27,154.4,454.71,70.72,425.43,33.9V8.53A7.72,7.72,0,0,1,433.15.82h55.29L544,70.72l21.89,27.53,21.9-27.53L643.42.82h54.45a7.72,7.72,0,0,1,7.72,7.71"/>
								<line fill="#333333" class="cls-3" x1="869.92" y1="207.29" x2="864.42" y2="196.62"/>
								<path fill="#333333" class="cls-2" d="M962.18,224.37c1.58,3.12.88,6.2-1,9.24l-.26.34c-2,2.11-3.73,3.88-6.52,4.12-.24,0-.5,0-.75,0H889.7a8.31,8.31,0,0,1-.88-.31h0a7.46,7.46,0,0,1-3.47-3l-3.6-5.9-33.54-54.83-1.74-2.86,0-.07a8,8,0,0,1-.69-1.49,7.63,7.63,0,0,1-.39-2.4V83h39.3a6.53,6.53,0,0,0,0-13.06h-153a7.59,7.59,0,0,1-7.6-7.58V7.58A7.59,7.59,0,0,1,731.68,0H888.76a84.84,84.84,0,0,1,83.51,69.89A83.88,83.88,0,0,1,973.55,83q0,.93,0,1.86a84.92,84.92,0,0,1-46.23,75.55l34.72,63.79a2.26,2.26,0,0,1,.11.22"/>
								<path fill="#333333" class="cls-2" d="M994.63,238.3h-14a2.93,2.93,0,0,1-2.93-2.92V223.21a2.92,2.92,0,0,1,2.93-2.92h14a2.92,2.92,0,0,1,2.92,2.92v12.17a2.92,2.92,0,0,1-2.92,2.92"/>
								<path fill="#333333" class="cls-2" d="M807.58,154.39H731.79a7.71,7.71,0,0,1-7.71-7.71V92.5a7.71,7.71,0,0,1,7.71-7.72h75.79a7.72,7.72,0,0,1,7.71,7.72v54.18a7.71,7.71,0,0,1-7.71,7.71"/>
								<path fill="#333333" class="cls-2" d="M825.93,237.64H731.79a7.71,7.71,0,0,1-7.71-7.71V175.45a7.72,7.72,0,0,1,7.71-7.72h94.14a7.72,7.72,0,0,1,7.71,7.72v54.48a7.71,7.71,0,0,1-7.71,7.71"/>
								<path fill="#333333" class="cls-1" d="M142.82,91.91V149.3A7.72,7.72,0,0,1,135.1,157H72.81v73.38a7.71,7.71,0,0,1-7.71,7.71H7.71A7.71,7.71,0,0,1,0,230.39V92a7.71,7.71,0,0,1,6.13-7.55h0a7.46,7.46,0,0,1,2-.27H135.1a7.72,7.72,0,0,1,7.72,7.71"/>
							</g>
						</g>
					</svg>
				</a>
			</v-row>
		</v-navigation-drawer>

		<v-app-bar app dark height="100">
			<v-app-bar-nav-icon color="psgreydark" class="d-flex d-lg-none order-1 order-lg-0 ml-auto" @click.stop="drawer = !drawer"/>
			<div class="c-page-title">
				<v-toolbar-title class="c-page-title__header text-uppercase display-1 font-weight-black">
					{{ pageTitle }}
				</v-toolbar-title>
			</div>
		</v-app-bar>

	</nav>
</template>

<script>
	import {mapGetters, mapActions} from 'vuex';
	import psUser from '../User'

	export default {
		components: {
			psUser
		},
		data: () => {
			return {
				drawer: null,
				brandMenuItems: [
					{icon: 'fa-tachometer-alt', title: 'Dashboard', route: 'brandDashboard'},
					{icon: 'fa-paperclip', title: 'Briefs', route: 'brandBriefs'},
					{icon: 'fa-photo-video', title: 'Treatments', route: 'brandTreatments'},
					{icon: 'fa-file-video', title: 'Projects', route: 'projects'},
					{icon: 'fa-user', title: 'Profile', route: 'profile'},
					// {icon: 'fa-question-circle', title: 'Help', route: 'brandHelp'},
				],
				creativeMenuItems: [
					{icon: 'fa-tachometer-alt', title: 'Dashboard', route: 'creativeDashboard'},
					{icon: 'fa-paperclip', title: 'Briefs', route: 'creativeBriefs'},
					{icon: 'fa-photo-video', title: 'Treatments', route: 'creativeTreatments'},
					{icon: 'fa-file-video', title: 'Projects', route: 'projects'},
					{icon: 'fa-user', title: 'Profile', route: 'profile'},
					{icon: 'fa-question-circle', title: 'Help', route: 'creativeHelp'},
				],
				darkTheme: true
			}
		},
		computed: {
			...mapGetters({
				// map `this.doneCount` to `this.$store.getters.doneTodosCount`
				authenticated: 'auth/authenticated',
				user: 'auth/user',
				isImpersonating: 'auth/impersonating',
				myBrands: 'auth/myBrands'
			}),
			pageTitle() {
				return this.$store.state.pageTitle;
			},
			menuItems() {
				let items = [];
				if (this.user && this.user.type === 'Brand') {
					items = this.brandMenuItems;
				} else if (this.user && this.user.type === 'Creative') {
					items = this.creativeMenuItems;
				}
				return items;
			},
		},
		methods: {
			...mapActions({
				stopImpersonating: 'auth/stopImpersonate'
			}),
			stopImersonating() {
				this.stopImpersonating()
				.then(() => {
					this.$router.replace({name: 'root'}, () => {
						this.$store.commit('setFlash', {
							type: 'success',
							message: 'User switching has stopped'
						});
					});
				});
			}
		},
		mounted() {
			this.data = !this.$vuetify.breakpoint.mdAndDown;
		}
	}
</script>

<style lang="scss" scoped>

	.menu-divider {
		border: none;
	}

	.v-navigation-drawer {
		box-shadow: $custom-shadow-elevation-2;
	}

	.v-list-item {
		&--active {
			background-color: var(--v-primary-base);
		}
	}

	.c-page-title {
		display: flex;
		align-items: center;
		max-width: calc(100% - (#{$navbar-page-title-padding-left} + #{$navbar-page-title-border-left-width } + 48px));
		padding-left: $navbar-page-title-padding-left !important;
		border-left: $navbar-page-title-border-left-width solid transparent;
		border-image-source: var(--squiggle-vertical);
		border-image-slice: 0 0 0 100%;
		border-image-width: 0px 0px 0px $navbar-page-title-border-left-width;
		border-image-outset: 0px 0px 0px 0px;
		border-image-repeat: stretch;
		min-height: 44px;

		@media #{$screen-lg-up} {
			max-width: none;
			padding-left: $navbar-page-title-padding-left-lg-up !important;
			white-space: normal;
		}
		&__header {
			color: var(--header-color);
			line-height: 1 !important;
			white-space: normal;
		}
	}

	// ::v-deep is necessary here to add styles to v-toolbar__content, which is used by Vuetify inside the v-app-bar component
	.ps-navbar::v-deep {
		.v-toolbar {
			box-shadow: none;
			&__content {
				@media #{$screen-lg-up} {
					padding-right: $dashboard-container-padding-x;
					padding-left: calc(#{$dashboard-container-padding-x} - #{$navbar-page-title-padding-left-lg-up});
				}
				@media #{$screen-xl-up} {
					padding-right: $dashboard-container-padding-x-xl-up;
					padding-left: calc(#{$dashboard-container-padding-x-xl-up} - #{$navbar-page-title-padding-left-lg-up});
				}
			}
		}
		.v-list-item--active {
			color: white;
		}
	}

</style>
